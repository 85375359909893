<template>
  <div class="mt-4">
    <div class="my-4 d-flex align-items-center justify-content-between">
      <h1 class="page-title">
        {{ $t("singleUser.title") }}
      </h1>

      <div>
        <b-button class="mr-2" variant="primary" pill @click="$router.go(-1)">
          <b-icon-arrow90deg-left class="mr-2" />
          {{ $t("back") }}
        </b-button>
        <b-button
          v-show="!setupComplete"
          class="shadow-sm"
          data-cy="resend-invitation"
          pill
          variant="primary"
          v-on:click="resendInvitation"
        >
          <b-icon-mailbox class="mr-2" />
          {{ $t("singleUser.invitation") }}
        </b-button>
      </div>
    </div>

    <b-alert v-model="showDismissibleAlert" dismissible fade variant="danger">
      {{ errorMessage }}
    </b-alert>

    <b-overlay :opacity="0.95" :show="loading" rounded="sm">
      <b-card
        :title="$t('singleUser.cardTitle')"
        border-variant="light"
        class="mt-4 shadow-sm card-rounded"
      >
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <b-form @submit.prevent="handleSubmit(saveChanges)">
            <b-form-group
              id="group-username"
              :label="$t('username')"
              label-for="username"
            >
              <b-form-input
                id="username"
                v-model="username"
                data-cy="edit-user-username"
                disabled
              />
            </b-form-group>

            <ValidationProvider
              v-slot="{ errors, pristine, valid }"
              name="First Name"
              rules="alpha"
            >
              <b-form-group
                id="group-name"
                :label="$t('firstName')"
                label-for="firstName"
              >
                <b-form-input
                  id="firstName"
                  v-model="form.firstName"
                  :placeholder="$t('firstNamePlaceholder')"
                  :state="getValidationState(errors, pristine, valid)"
                  data-cy="edit-user-firstname"
                />
                <b-form-invalid-feedback id="live-feedback-first-name">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, pristine, valid }"
              name="Last Name"
              rules="alpha"
            >
              <b-form-group
                id="group-name"
                :label="$t('lastName')"
                label-for="lastName"
              >
                <b-form-input
                  id="lastName"
                  v-model="form.lastName"
                  :placeholder="$t('lastNamePlaceholder')"
                  :state="getValidationState(errors, pristine, valid)"
                  data-cy="edit-user-lastname"
                />
                <b-form-invalid-feedback id="live-feedback-last-name">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <ValidationProvider
              v-slot="{ errors, valid }"
              name="E-mail Address"
              rules="required|email"
            >
              <b-form-group
                id="group-email"
                :label="$t('email')"
                label-for="e-mail"
              >
                <b-form-input
                  id="e-mail"
                  v-model="form.email"
                  :placeholder="$t('emailPlaceholder')"
                  :state="getValidationState(errors, valid)"
                  data-cy="edit-user-email"
                />

                <b-form-invalid-feedback id="live-feedback-email">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </ValidationProvider>

            <b-form-group
              id="group-enabled"
              :label="$t('singleUser.enable')"
              class="mb-0"
              label-for="enabled"
            >
              <b-form-checkbox
                v-model="form.enabled"
                data-cy="edit-user-enabled"
                size="lg"
                switch
              >
                {{ form.enabled ? "enabled" : "disabled" }}
              </b-form-checkbox>
            </b-form-group>

            <div class="d-flex justify-content-end">
              <b-button
                :disabled="disableSave"
                variant="primary"
                data-cy="edit-user-submit-button"
                pill
                type="submit"
              >
                {{ $t("singleUser.button") }}
              </b-button>
            </div>
          </b-form>
        </ValidationObserver>
      </b-card>
    </b-overlay>

    <b-overlay :opacity="0.95" :show="loading" rounded="sm">
      <b-card
        :title="$t('singleUser.organization')"
        border-variant="light"
        class="mt-4 shadow-sm card-rounded"
      >
        <b-form-group
          :label="$t('singleUser.changeParentOrg')"
          label-for="change-parent-org"
        >
          <b-form-select
            id="change-parent-org"
            v-model="selected"
            :options="tenants"
          />
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button
            v-b-modal.modal-center
            :disabled="selected === initialSelected"
            variant="primary"
            data-cy="change-parent-org-button"
            pill
            type="submit"
            @click="changeOrgModal()"
          >
            {{ $t("singleUser.button") }}
          </b-button>
        </div>
      </b-card>
    </b-overlay>

    <b-overlay :opacity="0.95" :show="loading" rounded="sm">
      <b-card
        :title="$t('singleUser.manageRoles')"
        border-variant="light"
        class="mt-4 shadow-sm card-rounded"
      >
        <b-form-group label-for="tags-with-dropdown">
          <b-form-tags
            id="tags-with-dropdown"
            v-model="value"
            class="mb-2"
            no-outer-focus
            size="lg"
            tag-pills
          >
            <template v-slot="{ tags, disabled, addTag, removeTag }">
              <ul
                v-if="tags.length > 0"
                class="list-inline d-inline-block mb-2"
                data-cy="single-user-roles"
              >
                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                  <b-form-tag
                    :data-cy="`single-user-role-tag-${toText(tag).replace(
                      /[_@]/g,
                      '-'
                    )}`"
                    :disabled="disableTag(tag)"
                    :title="toText(tag)"
                    variant="success"
                    @remove="onRemoveClick(tag, removeTag)"
                  >
                    {{ toText(tag) }}
                  </b-form-tag>
                </li>
              </ul>

              <b-dropdown
                block
                data-cy="single-user-role-dropdown"
                menu-class="w-100"
                size="sm"
                variant="outline-secondary"
              >
                <template #button-content>
                  <b-icon icon="tag-fill"></b-icon>
                  {{ $t("chooseRoles") }}
                </template>
                <b-dropdown-form @submit.stop.prevent="() => {}">
                  <b-form-group
                    :description="searchDesc"
                    :disabled="disabled"
                    :label="$t('searchRoles')"
                    class="mb-0"
                    label-cols-md="auto"
                    label-for="tag-search-input"
                    label-size="sm"
                  >
                    <b-form-input
                      id="tag-search-input"
                      v-model="search"
                      autocomplete="off"
                      size="sm"
                      type="search"
                    ></b-form-input>
                  </b-form-group>
                </b-dropdown-form>

                <b-dropdown-divider />

                <b-dropdown-group id="dropdown-group-1">
                  <b-dropdown-item-button
                    v-for="option in availableOptions"
                    :key="option.id"
                    data-cy="single-user-role-dropdown-item"
                    @click="onOptionClick({ option, addTag })"
                  >
                    {{ option.text }}
                  </b-dropdown-item-button>
                </b-dropdown-group>
                <b-dropdown-text v-if="availableOptions.length === 0">
                  {{ $t("rolesEmpty") }}
                </b-dropdown-text>
              </b-dropdown>
            </template>
          </b-form-tags>
        </b-form-group>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { email, min, required, alpha } from "vee-validate/dist/rules";

extend("email", email);
extend("min", min);
extend("alpha", alpha);
extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  name: "SingleUser",
  data() {
    return {
      form: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
        enabled: false,
      },
      initialForm: {
        id: "",
        firstName: "",
        lastName: "",
        email: "",
      },
      passwordForm: {
        old: "",
        new: "",
        confim: "",
      },
      username: "",
      setupComplete: true,
      showDismissibleAlert: false,
      errorMessage: "",
      options: [],
      search: "",
      value: [],
      loading: true,
      tenants: [{ value: "", text: "" }],
      selected: "",
      initialSelected: "",
    };
  },
  created() {
    this.getUserData();
  },
  methods: {
    changeOrgModal() {
      const h = this.$createElement;
      const text = h("div", [
        h(
          "p",
          "Are you sure that you want to change this users parent Organization?"
        ),
        h("p", [
          h("strong", "Attention: "),
          "This will also change the login credentials for the user (the username will change)",
        ]),
      ]);

      this.$bvModal
        .msgBoxConfirm([text], {
          title: "Change Parent Organization",
          size: "md",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: "Yes",
          cancelTitle: "No",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) this.changeOrg();
        })
        .catch((err) => {
          if (err.response) console.log(err.response);
          // An error occurred
        });
    },

    async changeOrg() {
      this.loading = true;
      try {
        await this.$http.patch(
          `${this.$cfg.BASE_IDENTITY_URL}/v0/accounts/${this.form.id}/change-tenant`,
          {
            tenantId: this.selected,
          }
        );

        await this.getUserData();
      } catch (err) {
        console.log(err);
      }
    },

    // save changes
    async saveChanges() {
      this.loading = true;
      try {
        const {
          data: { firstName, lastName, email, enabled },
        } = await this.$http.patch(
          `${this.$cfg.BASE_IDENTITY_URL}/v0/accounts/${this.form.id}`,
          this.form
        );

        this.form = {
          ...this.form,
          firstName: firstName,
          lastName: lastName,
          email: email,
          enabled: enabled,
        };
        this.initialForm = {
          ...this.initialForm,
          firstName: firstName,
          lastName: lastName,
          email: email,
          enabled: enabled,
        };

        this.form.firstName = firstName;
        this.form.lastName = lastName;
        this.form.email = email;
        this.form.enabled = enabled;

        // resests vee validation after the changes were saved
        this.$nextTick(() => {
          this.$refs.observer.reset();
        });

        this.$bvToast.toast("Changes were saved", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    async resendInvitation() {
      try {
        await this.$http.post(
          `${this.$cfg.BASE_IDENTITY_URL}/v0/accounts/${this.form.id}/resend-invitation`
        );

        this.$bvToast.toast("Invitation link was sent!", {
          title: `Success`,
          toaster: "b-toaster-bottom-right",
          variant: "success",
          solid: true,
        });
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // Go back
    routeBack() {
      this.$router.push({ path: "/admin/users" });
    },

    // Validate form
    getValidationState(errors, pristine, valid) {
      if (this.form.email?.length === 0) {
        return null;
      }

      if (pristine) {
        return null;
      }
      return errors[0] ? false : valid ? true : false;
    },

    // Get the user information
    async getUserData() {
      this.loading = true;
      try {
        const { data: userData } = await this.$http.get(
          `${this.$cfg.BASE_IDENTITY_URL}/v0/accounts/${this.$route.params.username}`
        );

        const { data: response } = await this.$http.get(
          `${this.$cfg.BASE_IDENTITY_URL}/v0/accounts/${userData.id}/roles`
        );

        await this.getOrgs();
        await this.getRoles();

        this.username = userData.name.split("/")[1];
        this.form = {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          enabled: userData.enabled,
        };
        this.initialForm = {
          id: userData.id,
          firstName: userData.firstName,
          lastName: userData.lastName,
          email: userData.email,
          enabled: userData.enabled,
        };
        this.setupComplete = userData.setupComplete;

        this.selected = userData.tenant.id;
        this.initialSelected = userData.tenant.id;

        this.value = [];

        response.roles.forEach((el) => {
          this.value.push(
            JSON.stringify({ value: el.id, text: el.displayName })
          );
        });
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },

    // Get roles
    async getRoles() {
      const response = await this.$http.get(
        this.$cfg.BASE_IDENTITY_VERSION_URL + "/roles"
      );
      response.data.roles.forEach((el) => {
        this.options.push({ value: el.id, text: el.displayName });
      });
    },

    async getOrgs() {
      // get tenants
      const {
        data: { content },
      } = await this.$http.get(
        this.$cfg.BASE_IDENTITY_VERSION_URL + "/tenants",
        {
          params: {
            pageSize: 1000,
            excludeUserCount: true,
          },
        }
      );

      this.tenants = [{ value: "", text: "" }];

      // get all tenants
      content.forEach((el) => {
        this.tenants.push({ value: el.id, text: el.displayName });
      });
    },

    // add tag on click
    async onOptionClick({ option, addTag }) {
      try {
        await this.$http.put(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/accounts/${this.form.id}/roles`,
          {
            roleIds: [option.value],
          }
        );
        addTag(JSON.stringify(option));
        this.search = "";
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },

    // remove tag
    async onRemoveClick(tag, removeTag) {
      try {
        const option = JSON.parse(tag);
        await this.$http.delete(
          `${this.$cfg.BASE_IDENTITY_VERSION_URL}/accounts/${this.form.id}/roles/${option.value}`
        );
        removeTag(tag);
        this.search = "";
      } catch (err) {
        if (err.response) console.log(err.response);
      }
    },
    // get display text for tag
    toText(tag) {
      return JSON.parse(tag).text;
    },

    // disable tag removal
    disableTag(tag) {
      let disable = true;
      this.options.forEach((el) => {
        if (JSON.stringify(el) === tag) {
          disable = false;
        }
      });
      return disable;
    },
  },

  computed: {
    criteria() {
      // Compute the search criteria
      return this.search.trim().toLowerCase();
    },
    availableOptions() {
      const criteria = this.criteria;
      // Filter out already selected options
      const options = this.options.filter(
        (opt) => this.value.indexOf(JSON.stringify(opt)) === -1
      );

      if (criteria) {
        // Show only options that match criteria
        return options.filter(
          (opt) => opt.text.toLowerCase().indexOf(criteria) > -1
        );
      }
      // Show all options available
      return options;
    },
    searchDesc() {
      if (this.criteria && this.availableOptions.length === 0) {
        return this.$i18n.t("searchRolesempty");
      }
      return "";
    },
    mappedTags() {
      return this.value.map((item) => JSON.parse(item).text);
    },
    disableSave() {
      return (
        this.form.email === this.initialForm.email &&
        this.form.firstName === this.initialForm.firstName &&
        this.form.lastName === this.initialForm.lastName &&
        this.form.enabled === this.initialForm.enabled
      );
    },
  },
};
</script>

<style scoped>
#dropdown-group-1 {
  max-height: 350px;
  overflow-y: auto;
}
</style>
